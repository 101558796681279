import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Table from '@frontend/ui-kit/Components/Table';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Link from '@frontend/ui-kit/Components/Link';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import withPopup from '../../../../HOC/withPopup';
import {requestEligibilityImportMigratedMembers, requestImportsJsonrpc} from '../../../../actions/adminPortal';
import {equal, generateUniqueId, getEqual, getItemKeyValue, groupBy, isBoolean, isEmpty} from '../../../../utils';
import {CRM_URL, LABEL_BY_RELATIONSHIP, RELATIONSHIPS} from '../../../../constants';

const POPUP_ID = 'eligibilityImportMigrationPopup';

const TABLE_PAGE_SIZE = 20;

const Migration = ({openPopup, closePopup, isEditable, importData}) => {
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0, filterBy: null});
    const [tableData, setTableData] = useState([]);
    const {id: importId} = useParams();
    const dispatch = useDispatch();
    const [allFetchedMigratedMembers, setAllFetchedMigratedMembers] = useState([]);
    const [isAllMembersSelected, setIsAllMembersSelected] = useState(false);
    const [selectedIds, setSelectedIds] = useState({approved: [], noApproved: []});
    const [toggleAllRowsSelected, setToggleAllRowsSelected] = useState(null);

    const approveMembersCount = useMemo(() => {
        return isAllMembersSelected && !table.filterBy && selectedIds.noApproved.length ? table.unitCount - selectedIds.approved.length : selectedIds.noApproved.length;
    }, [isAllMembersSelected, table, selectedIds]);

    const removeApprovalMembersCount = useMemo(() => {
        return isAllMembersSelected && !table.filterBy && !selectedIds.noApproved.length ? table.unitCount : selectedIds.approved.length;
    }, [isAllMembersSelected, table, selectedIds]);

    const prepareTableData = migratedMembers => migratedMembers
        .reduce((acc, member, index) => {
            const {employee_id: employeeId, relationship_field: relationshipField} = member;

            if (!equal(relationshipField, RELATIONSHIPS.self)) {
                const employeeIndex = acc.findIndex(({employee_id: id, relationship_field: relationship}) => equal(id, employeeId) && equal(relationship, 'self'));

                if (equal(employeeIndex, -1)) {
                    acc[index] = {...member, no_self: true};
                } else {
                    acc[employeeIndex].subRows = (acc[employeeIndex].subRows ? [...acc[employeeIndex].subRows, member] : [member]);
                }
            }

            return acc;
        }, migratedMembers)
        .filter(({relationship_field, no_self}) => equal(RELATIONSHIPS.self, relationship_field) || no_self);

    const setMigratedMembersData = ({count, migratedMembers, pageIndex, filterBy}) => {
        const enhancedMigratedMembers = migratedMembers.map(member => ({...member, full_name: `${member?.last_name}, ${member?.first_name}`}));
        const getUniqueMember = (value, index, self) => equal(self.findIndex(obj => equal(obj.account_id, value.account_id)), index);

        setAllFetchedMigratedMembers(state => [...migratedMembers, ...state].filter(getUniqueMember));
        setTable({unitCount: count, pageIndex, filterBy});
        setTableData(prepareTableData(enhancedMigratedMembers));
    };

    const onFetchData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE, filterBy}) => {
        const query = {
            id: importId,
            limit: pageSize,
            offset: pageIndex * pageSize,
            ...filterBy && {
                name: filterBy,
                email: filterBy,
                employee_id: filterBy
            }
        };
        const {isSuccess, migratedMembers, count} = await dispatch(requestEligibilityImportMigratedMembers(query));

        if (!isSuccess) {
            return false;
        }

        setMigratedMembersData({migratedMembers, count, pageIndex, filterBy});
    }, [importId, dispatch]);

    const getRelatedMembers = useCallback(accountIds => accountIds.reduce((acc, accountId) => {
        const {subRows = []} = allFetchedMigratedMembers.find(getEqual(accountId, 'account_id')) || {};
        const relatedMembers = subRows.map(getItemKeyValue('account_id'));

        return [...acc, ...relatedMembers, accountId];
    }, []), [allFetchedMigratedMembers]);

    const setSelectedData = useCallback(selectedRowIds => {
        const selectedRowsData = selectedRowIds.map(accountId => allFetchedMigratedMembers.find(getEqual(Number(accountId), 'account_id')));
        const {true: approvedMembers = [], false: noApprovedMembers = []} = groupBy(selectedRowsData, 'migrate_to_another_company');
        const [approvedIdsList, noApprovedIdsList] = [approvedMembers, noApprovedMembers]
            .map(members => members.map(getItemKeyValue('account_id')))
            .map(getRelatedMembers);

        setSelectedIds({approved: approvedIdsList, noApproved: noApprovedIdsList});
    }, [allFetchedMigratedMembers, getRelatedMembers]);

    const onSelectRow = useCallback(({selectedRowIds, isAllSelected, toggleAllRowsSelected}) => {
        if (!isEmpty(selectedRowIds)) {
            setSelectedData(Object.keys(selectedRowIds));
        }
        setIsAllMembersSelected(isAllSelected);
        setToggleAllRowsSelected(() => toggleAllRowsSelected);
    }, [setSelectedData]);

    const onOpenPopup = (popupContent, actionBar) => {
        const popupProps = {title: 'Confirmation Required', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const updateMigratedMembers = useCallback(async isApprove => {
        const jsonrpcObj = {
            jsonrpc: '2.0',
            method: isApprove ? 'approve_migrated_members' : 'remove_approval_migrated_members',
            id: generateUniqueId(),
            params: {
                session_id: Number(importId),
                account_ids: isApprove ? selectedIds.noApproved : selectedIds.approved,
                all_migrated_members: isApprove ? isAllMembersSelected : equal(removeApprovalMembersCount, table.unitCount)
            }
        };
        const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

        if (!equal(jsonrpc?.result, 'ok')) {
            return false;
        }

        toggleAllRowsSelected(false);
        setIsAllMembersSelected(false);
        setSelectedIds({approved: [], noApproved: []});
        onFetchData({pageIndex: table?.pageIndex, pageSize: TABLE_PAGE_SIZE, filterBy: table?.filterBy});
    }, [importId, isAllMembersSelected, table, onFetchData, toggleAllRowsSelected, removeApprovalMembersCount, selectedIds]);

    const onApprove = () => {
        const onApproveMigration = () => {
            closePopup();
            updateMigratedMembers(true);
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='cancel-migration-approve-popup-button' type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button data-testid='approve-migration-button' type={BUTTON_TYPES.primary} onClick={onApproveMigration} className='approve-migration'>Yes, Approve Migration</Button>
            </React.Fragment>
        );
        const popupContent = <Text>Are you sure you want to approve migration for {approveMembersCount} Members?</Text>;

        onOpenPopup(popupContent, actionBar);
    };

    const onRemoveApproval = () => {
        const onApproveMigration = () => {
            closePopup();
            updateMigratedMembers();
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='cancel-migration-approval-button' type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button data-testid='remove-migration-approval-button' type={BUTTON_TYPES.primary} onClick={onApproveMigration} className='approve-migration'>Yes, Remove Migration Approval</Button>
            </React.Fragment>
        );
        const popupContent = <Text>Are you sure you want to remove migration approval for {removeApprovalMembersCount} Members?</Text>;

        onOpenPopup(popupContent, actionBar);
    };

    const getTableColumns = () => {
        const getMultiInstance = ({value}) => value && <Icon type={ICON_TYPES.checkCircle} className='multi-instance-icon'/>;

        const getStatus = ({value}) => value && <Sticker type={STICKER_TYPES.primary}>Approved</Sticker>;

        const getSystem = ({value: accountId}) => accountId && <Link data-testid='system-button' href={`${CRM_URL}/pha/crm/${accountId}`} target='_blank'>CRM</Link>;

        const getRelationship = ({value: relationship}) => LABEL_BY_RELATIONSHIP[relationship];

        return [
            {Header: 'Name', accessor: 'full_name', width: 200},
            {Header: 'Email', accessor: 'email', width: 200},
            {Header: 'Employee ID', accessor: 'employee_id', width: 200},
            {Header: 'Relationship', accessor: 'relationship_field', width: 200, Cell: getRelationship, isFilterable: false},
            {Header: 'Migration From', accessor: 'migrate_from', width: 200, isFilterable: false},
            {Header: 'Multi-Instance', accessor: 'multi_instance', width: 200, Cell: getMultiInstance, align: 'center', isFilterable: false},
            {Header: 'Migration Status', accessor: 'migrate_to_another_company', width: 200, Cell: getStatus, isFilterable: false},
            {Header: 'System', accessor: 'account_id', width: 220, Cell: getSystem, isFilterable: false}
        ];
    };

    const filterActionBar = (
        <div className='migration-action-bar'>
            {!!approveMembersCount && (
                <Button data-testid='approve-button' onClick={onApprove} className='mr-10' iconLeft={<Icon type={ICON_TYPES.checkCircle}/>}>
                    Approve ({approveMembersCount})
                </Button>
            )}
            {!!removeApprovalMembersCount && (
                <Button data-testid='remove-approval-button' onClick={onRemoveApproval} type={BUTTON_TYPES.destructive}>
                    Remove Approval ({removeApprovalMembersCount})
                </Button>
            )}
        </div>
    );

    const tableProps = {
        data: tableData,
        isSortable: false,
        isFilterable: true,
        filterPlaceholder: 'Search by name, email, or employee ID',
        className: 'mt-10',
        columns: getTableColumns(),
        isSubRowsSelectable: false,
        pageSize: TABLE_PAGE_SIZE,
        filterActionBar,
        onFetchData,
        ...isEditable && {
            onSelectRow,
            tableConfig: {
                autoResetSelectedRows: false,
                getRowId: row => row.account_id
            }
        },
        ...table
    };

    return (
        <React.Fragment>
            {isEditable && <Alert className='mb-20' type={ALERT_TYPES.danger} title='Thresholds' description='Verification required. Approve affected members.'/>}

            <Heading type={HEADING_TYPES['1']}>{importData?.import_info?.thresholds?.migrated_members?.count ?? 0} Members Migrated From Another Company</Heading>

            <ContentSection className='mt-20'>
                {Array.isArray(tableData) && isBoolean(isEditable) && <Table {...tableProps}/>}
            </ContentSection>
        </React.Fragment>
    );
};

Migration.propTypes = {
    isEditable: PropTypes.bool.isRequired,
    importData: PropTypes.shape({
        import_info: PropTypes.shape({
            thresholds: PropTypes.shape({
                migrated_members: PropTypes.shape({
                    count: PropTypes.number
                })
            })
        })
    }),
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {Migration as TestableMigration};
export default withPopup(POPUP_ID)(Migration);
